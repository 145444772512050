.favorites {
  min-height: 80vh;

  &__table {

    &__button_style {
      color: #646464;

      &:hover {
        color: #7ac79b;
      }
    }

    a:hover {
        text-decoration: underline;
    }
  }

  &__keywords {
    text-align: center;
  }

  &__keywords::after {
    content: attr(data-bold);
    font-weight: bold;
    visibility: hidden;
    height: 0;
    display: block;
  }

  &__keywords:hover {
    font-weight: bold;
    text-decoration: underline;
  }
}

.search__input {
    background-color: #fafafa;
    border: 1px solid #dfe1e5;
    border-radius: 100px;
  }
  
  .search__input__field {
    input {
      color: #262262;
      background-color: #ecf7ff;
  
      &::placeholder {
        color: #262262;
        opacity: 1;
        font-size: 1.25rem;
      }
    }
  }