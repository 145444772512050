.search__filter {
  span {
    i {
      font-size: 14px;
      font-weight: 500;
      color: #646464;
    }
  }
}

.search__list {
  padding-left: 0;

  &__active {
    color: #7ac79b !important;

    img {
      transform: rotate(180deg);
      filter: invert($color: #7ac79b)
    }
  }

  li {
    &:hover {
      color: #7ac79b;
    }
  }

}

.taxonomies {
  font-size: 16px;
  font-weight: 500;
  color: #646464;

  &:focus {
    color: #7ac79b;
  }
}

.tooltip {
  padding-left: 0;

  li {
    &:hover {
      background-color: transparent;
      color: unset;
    }
  }
}

.tippy {
  top: -1.2em;
  box-shadow: none !important;
  outline: 1px solid #ccc;
}