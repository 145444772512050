.carousel {
  &__preview {
    img {
      max-width: none;
    }
    & > div:nth-child(1) > div:nth-child(1) > div:nth-child(2) {
      // width: 25vw !important;
      // height: 50vh !important;
    }
  }

    &__thumbnail:first-child {
        div {
            div:last-of-type {
                position: relative;
                img {
                max-width: 100%;
                }
            }
        }
    }
    &__thumbnail:first-child {
        div {
            div:last-of-type:before {
            content: "\f002";
            font-family: "Font Awesome 5 Free";
            display: flex;
            align-items: center;
            justify-content: center;
            font-style: normal;
            font-variant: normal;
            font-weight: 900;
            font-size: 8em;
            color: white;
            text-align: center;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .3);
            transition: opacity .3s;
            opacity: 0;
            z-index: 0;
            pointer-events: none;
            }
        }
    }

    &__thumbnail:first-child {
        div {
            div:last-of-type:hover:before {
            opacity: 1;
            }
        }
    }

  
}
// .react-pdf__Page:before {
//     content: "\f002";
//     font-family: "Font Awesome 5 Free";
//     display: inline-block;
//     font-style: normal;
//     font-variant: normal;
//     font-weight: 900;
//     font-size: 8em;
//     color: white;
//     padding-top: 35%;
//     text-align: center;
//     text-rendering: auto;
//     -webkit-font-smoothing: antialiased;
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-color: rgba(0, 0, 0, .3);
//     transition: opacity .3s;
//     opacity: 0;
//     z-index: 0;
//     pointer-events: none;
//   }
//   .react-pdf__Page:hover:before {
//     opacity: 1;
//   }
