@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("//use.fontawesome.com/releases/v5.15.3/css/all.css");
* {
  &:focus,
  &:focus-visible,
  &:active {
    outline: none !important;
  }
}

body {
  font-family: 'Montserrat', sans-serif !important;
  font-style: normal;
  margin: 0;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style: unset;
  padding-left: 20px;
}

ol {
    list-style: auto;
    padding-left: 20px;
}

.drop_down_results_list {
  list-style: disc;
}

a {
//   color: #5eaee0;
  color: #216FB4;
}

.App {
  overflow-x: hidden;
}

.search__copy__repository__input__placeholder {
  @media (max-width: 630px) {
    font-size: .85em;
  }
}

.shared__content {
  @media (max-width: 630px) {
    li {
      p, time, button, a {
        font-size: .85em;
      }
    }
  }
}

.tippy-arrow::before {
  border-bottom-color: #ccc !important;
  border-width: 1px !important;
}

.react-pdf__Document {
  position: relative;
  z-index: -1;
}

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table th {
  font-size: .85em;
  text-transform: uppercase;
}

.ReactModal__Content {
  max-height: 90vh;
  width: 50%;
  overflow-x: hidden !important;

  img {
    max-width: none;
  }
}

canvas {
  max-width: 100% !important;
  height: auto !important;
}

.react-pdf__Page__textContent,
.react-pdf__Page__annotations {
  display: none;
}

@media screen and (max-width: 768px) {
  table {
    border: 0 !important;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: flex;
    font-size: .8em;
    text-align: right;
    justify-content: space-between;
  }

  table tbody {
    border-bottom: none !important;
    tr {
      border-right: 1px solid #ddd;
      border-left: 1px solid #ddd;
    }
  }

  table tbody td {
    &:first-child {
      border-right: none !important;
    }
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}

img[alt="loading"] {
  transform: rotateY(-180deg);
}
