.shared__content {
  &__copy {
    font-size: 13px;
  }

  &__docs {
    font-size: 13px;
  }

  &__details {
    ul {
      padding: 0;

      li {
        p, a, button, time {
          font-size: 13px;
        }
      }
    }
  }
}