.nav {
  .navlink >
    a {
      color: #353535;
      padding: 1em;
      height: 88px;
      font-size: 16px;
      font-weight: 600;

      &:hover {
        background-color: #7ac79b;
      }
    }
    li {
        display: flex;
        align-items: center;
        height: 88px;
    }
  
}

.ul {
  div {
    @media (min-width: 1366px) {
      padding-left: 60px;
    }
  }
}

.header__actions {
  box-shadow: 0 0 10px #8c8c8c;

  @media (max-width: 630px) {
    background-color: transparent !important;
  }

  img {
    min-width: 170px;
    height: 54px;
  }
}

.header__green__divider {
  height: 10px;
  background-color: #7ac79b;
}



.menu {
    // define the height of the menu
    --menu-height: 88px;
    // holder and ul general style
    box-sizing: border-box;
    position: relative;
    padding-left: 0px !important;
    ul {
      list-style: none;
      padding: 0 16px;
      margin: 0;
      li, li a {
        color: #353535;      
        cursor: pointer;
        transition: 200ms;
        text-decoration: none;
        &:hover {
          opacity: 1;
        }
        a {
          display: flex;
          align-items: center;
          height: 100%;
          width: 100%;      
        }      
      }
      // lets put an arrow down 
      // to the li`s with dropdown
      li {
        padding-right: 36px;
        &::before {
          content: '';
          width: 0; 
          height: 0; 
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #000000;
          position: absolute;
          right: 8px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .link {
        // links dont need arrow
        &::before {
          padding-right: 0;
          display: none;
        }      
      }
    }
    // the first ul inside the container
    // is the menu, so must be visible 
    // and have its own style
    > ul {
      display: flex;
      height: var(--menu-height);
      align-items: center;
      background-color: #ffffff;
      font-size: 16px;
      font-weight: 600;
      &:hover {
        background-color: #7ac79b;
      }
      // the first ul elements can be a
      // link or an li with a nested ul. 
      // the nested ul will be a dropdown
      li {
        position: relative;
        margin: 0 0px;
        // the dropdown style
        ul {
          box-shadow: 0 0 10px #8c8c8c;
          visibility: hidden;
          opacity: 0;        
          padding: 0;
          min-width: 225px;
          background-color: #d4e1da;
          position: absolute;
          top: calc(var(--menu-height) );
        //   left: 50%;
          left: -16px;
        //   transform: translateX(-50%);
          transition: 200ms;
          transition-delay: 200ms;
          // the dropdown items style
          li {
            margin: 0;
            padding: 4px 12px 4px 16px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 100%;
            padding-right: 40px;
            // lets put an arrow right
            // to the inner li`s with
            // dropdowns
            &::before {
              content: '';
              width: 0; 
              height: 0; 
              border-top: 5px solid transparent;
              border-bottom: 5px solid transparent;
              border-left: 5px solid #000000;
              position: absolute;
              right: 8px;
              top: 50%;
              transform: translateY(-50%);
            }
            // every dropdown after the
            // first must open to the right
            ul {
              top: 0px;
              left: 100%;
              transform: translate(0)
            }
            &:hover {
              background-color: #7ac79b;
            }
          }
          li:nth-child(even) {
            background-color: #c3dfd0;
            &:hover {
                background-color: #7ac79b;
            }
          }
        }
        // on hover an li (not an <a>)
        // must show its ul (dropdown)
        &:hover {
          > ul {
            opacity: 1;
            visibility: visible;
            transition-delay: 0ms;
          }
        }
      }
    }
    > ul {
        li {
          ul.theme {
            min-width: 230px;
            li {
                padding-right: 20px;
            }
          }
          ul.language {
            min-width: 120px;
            li {
                padding-right: 20px;
            }
          }
        }
    }
  }