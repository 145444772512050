.details__panel {
  position: fixed;
  top: 0;
  float: right;
  right: 0;
  animation: fadeIn .1s linear;
  box-shadow: -50vw 0 0 0 rgba(0, 0, 0, .75);
  overflow: auto;
  height: 100vh;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  &__ap_link {
      display: block;
      font-size: 18px;
      margin-top: -2em;
      margin-bottom: 2em;
  }

  &__language_toggle {
    color: #216FB4;
    text-align: center;
  }

  &__language_toggle::after {
    content: attr(data-bold);
    font-weight: bold;
    visibility: hidden;
    height: 0;
    display: block;
  }

  &__language_toggle:hover {
    font-weight: bold;
    text-decoration: underline;
  }

  &__fav_toggle {
    color: #216FB4;
    font-weight: bold;
  }

  &__fav_toggle:hover {
    cursor: pointer;
  }

  &__unfav_toggle {
    font-weight: bold;
    color: #000000;
  }

  &__unfav_toggle:hover {
    cursor: pointer;
  }

  h3 {
    color: #2b2661;
    font-weight: 700;
    border-color: #2b2661;
  }

  a:hover {
    text-decoration: underline;
  }

  &__ap_link:hover {
    font-weight: bold;
  }

  &__copy {
    font-size: 13px;

    ul {
      padding-left: 20px;
    }
  }

  &__docs {
    font-size: 13px;
  }

  &__details {
    ul {
      padding: 0;

      li {
        p, a, button, time {
          font-size: 13px;
        }
      }
    }
  }

  &__close {
    height: 3em;
  }

  tbody {
    tr:not(:first-child) {
      border-top: 1px solid #d6d6d6;
    }
    border: 1px solid #979797;
  }
  

  @media (max-width: 630px) {
    li {
      p, time, button, a {
        font-size: .85em;
      }
    }
  }
}