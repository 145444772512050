.hidden1 {
    bottom: -80px;
}
.hidden2 {
    /* visibility: hidden; */
    /* height: 0px; */
    max-width: 0px;
    max-height: 0px;
}
.hiding {
    opacity: 0;
}
.gone {
    display: none;
}