.main {
    min-height: calc(100vh - 114px);
  }
  
  .search__results {
    background: #7ac79b;
    border-bottom: 10px solid #7ac79b;
  
    &__table {
      thead {
        th {
        //   &:first-child {
        //     width: 35%;
        //   }
          &:nth-child(2) {
            width: 8em;
          }
          &:nth-child(3) {
            width: 137px;
          }
          &:nth-child(4) {
            width: 28%;
          }
          &:nth-child(5) {
            width: 112px;
          }
          &:last-child {
            width: 6em;
          }
  
          &:last-child {
            div {
              justify-content: flex-end;
  
              span {
                display: none;
              }
            }
          }
        }
  
        img {
          width: 1em;
        }
      }
  
      tbody {
        tr {
          td {
              word-break: break-word;
          }
          td a {
              display: inline-block;
          }
          td:first-child {
            button {
              &:hover {
                color: #7ac79b;
              }
            }
  
            @media (max-width: 768px) {
              display: flex;
              flex-wrap: wrap;
              }
            }
         
  
          td:last-child {
            // text-align: center;
  
            button {
              svg:hover {
                color: #7ac79b;
              }
            }
          }
          a:hover {
              text-decoration: underline;
          }
        }
      }
  
      thead th:first-child,
      tbody td:first-child {
        border-right: 1px solid #979797;
      }
  
      tbody {
        border-bottom: 1px solid #979797;
  
        tr {
          border-top: 1px solid #d6d6d6;
        }
      }
    }
  }