.search__input {
  background-color: #fafafa;
  border: 1px solid #dfe1e5;
  border-radius: 100px;
}

.search__input__field {
  input {
    color: #262262;
    background-color: #ecf7ff;

    &::placeholder {
      color: #262262;
      opacity: 1;
      font-size: 1.25rem;
    }
  }
}
